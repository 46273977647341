
import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Navbar from "../components/nav-bar"

const TermsOfServices = () => (
    <html class="dark">

        <div class="dark:bg-darkbackg overflow-y-auto w-screen h-full">
            <Navbar />
            <SEO title="Terms of Services" />
            <main class="flex-col flex justify-center mx-auto h-full px-8 pt-6 lg:px-32">
                <h2 class="font-sans text-gray-400 self-start">Obsidian Terms of Service</h2>
                <h3 class="font-sans text-gray-400 self-start text-base">1. Terms</h3>
                <p class="font-sans text-gray-400 self-start text-base">By accessing our app, Obsidian, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing Obsidian. The materials contained in Obsidian are protected by applicable copyright and trademark law.</p>
                <h3  class="font-sans text-gray-400 self-start text-base">2. Use License</h3>
                <ol type="a" class="font-sans text-gray-400 self-start text-base">
                    <li  class="font-sans text-gray-400 self-start text-base">Permission is granted to temporarily download one copy of Obsidian per device for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
   <ol type="i">
                            <li  class="font-sans text-gray-400 self-start text-base">modify or copy the materials;</li>
                            <li  class="font-sans text-gray-400 self-start text-base">use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                            <li  class="font-sans text-gray-400 self-start text-base">attempt to decompile or reverse engineer any software contained in Obsidian;</li>
                            <li  class="font-sans text-gray-400 self-start text-base">remove any copyright or other proprietary notations from the materials; or</li>
                            <li  class="font-sans text-gray-400 self-start text-base">transfer the materials to another person or "mirror" the materials on any other server.</li>
                        </ol>
                    </li>
                    <li  class="font-sans text-gray-400 self-start text-base">This license shall automatically terminate if you violate any of these restrictions and may be terminated by Obsidian at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
                </ol>
                <h3  class="font-sans text-gray-400 self-start text-base">3. Disclaimer</h3>
                <ol type="a">
                    <li  class="font-sans text-gray-400 self-start text-base">The materials within Obsidian are provided on an 'as is' basis. Obsidian makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
                    <li  class="font-sans text-gray-400 self-start text-base">Further, Obsidian does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to Obsidian.</li>
                </ol>
                <h3  class="font-sans text-gray-400 self-start text-base">4. Limitations</h3>
                <p  class="font-sans text-gray-400 self-start text-base">In no event shall Obsidian or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use Obsidian, even if Obsidian or a Obsidian authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
                <h3  class="font-sans text-gray-400 self-start text-base">5. Accuracy of materials</h3>
                <p  class="font-sans text-gray-400 self-start text-base">The materials appearing in Obsidian could include technical, typographical, or photographic errors. Obsidian does not warrant that any of the materials on Obsidian are accurate, complete or current. Obsidian may make changes to the materials contained in Obsidian at any time without notice. However Obsidian does not make any commitment to update the materials.</p>
                <h3  class="font-sans text-gray-400 self-start text-base">6. Links</h3>
                <p  class="font-sans text-gray-400 self-start text-base">Obsidian has not reviewed all of the sites linked to its app and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Obsidian of the site. Use of any such linked website is at the user's own risk.</p>
                <h3  class="font-sans text-gray-400 self-start text-base">7. Modifications</h3>
                <p  class="font-sans text-gray-400 self-start text-base">Obsidian may revise these terms of service for its app at any time without notice. By using Obsidian you are agreeing to be bound by the then current version of these terms of service.</p>
                <h3  class="font-sans text-gray-400 self-start text-base">8. Governing Law</h3>
                <p  class="font-sans text-gray-400 self-start text-base">These terms and conditions are governed by and construed in accordance with the laws of Italy and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
            </main>
        </div>
    </html>
)

export default TermsOfServices
